* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}
.container {
  margin: 0 auto;
  max-width: 1280px;
}
textarea {
  font-family: Roboto, Arial, sans-serif !important;
}
table {
  width: 100%;
}
.App {
  /* color: #1b1b1b;  */
}

body {
  font-family: Roboto, Arial, sans-serif;
  letter-spacing: 0;
  font-family: "Ubuntu", sans-serif;
  background: #1b1b1b;
}

/* */
.under-constru {
  background-color: rgba(255, 89, 84, 1);
  color: rgba(255, 255, 255, 0.831);
  text-align: center;
  padding: 4px 0;
}
ul {
  list-style: none;
  display: flex;
}
.header-main {
  width: 100%;
  right: 0;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15vh;
  user-select: none;
}
.header-nav {
  width: 52%;
}
.header-nav li {
  cursor: pointer;
  font-weight: 600;
  /* font-size: 20px; */
  /* color: #1b1b1b; */
}
.header-nav li:hover {
  /* text-decoration: #1b1b1b66 line-through; */
}

.header-nav #letstalk:hover {
  transition: all 0.4s ease;
  border-radius: 25%;
  text-decoration: none;
}
.header-logo h1 {
  /* font-size: 4rem;
  font-family: "Babylonica", cursive;
  font-weight: 600; */
}
.header-logo a {
  color: #1b1b1b;
  text-decoration: none;
}
.header-moonIcon {
  /* color: #1b1b1baf;  */
  /* font-size: 1.5rem; */
  border: 2px #1b1b1baf solid;
  padding: 5px;
  border-radius: 50%;
}
/* */
/*** Example Styles ****/

/* end */

/*  */
.backButton {
  padding: 10px;
  color: #0c0c0ce8;
  border: 2px #0c0c0ce8 solid;
  border-radius: 50%;
}

.pagenotfound-h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slider-main {
  display: flex;
  height: 85vh;
  align-items: center;
  justify-content: space-evenly;
}
.slider-heading h3 {
  font-size: 1.5rem;
  padding: 2px 0;
  color: #1b1b1bad;
}
.slider-icon img {
  position: relative;
  width: 257px;
  height: 257px;
  border-radius: 50%;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.slider-icon img:hover {
}
.slider-heading h1 {
  /* font-family: "Chilanka", cursive;  */
  padding: 12px 0;
  font-size: 4.5rem;
  text-align: left;
}
.slider-heading p {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.5rem;
  opacity: 0.8;
  margin-left: 2px;
}

.slider-down-arrow {
  height: 10vh;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.down-arrow-icon {
  font-size: 35px;
  color: #1e1e1e;
  transition: all 2s ease;
  z-index: 77;
}
/*  */
.center {
  width: 50px;
  height: 50px;
}
.center:hover .down-arrow-icon {
  color: white;
  transition: all 2s ease;
}
.circle {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 2px solid #1e1e1e;
  border-radius: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wave {
  background-color: #1e1e1e;
  position: absolute;
  top: 110%;
  height: 200%;
  width: 200%;
  border-radius: 38%;
  left: -50%;
  transform: rotate(360deg);
  transition: all 1.5s ease;
  animation: wave 50s linear infinite;
}
@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3600deg);
  }
}
.circle:hover .wave {
  top: 0%;
}
.circle:hover {
  border: 2px solid white;
  transition: all 2s ease;
}
/*  */

.detailscard-main {
  padding: 60px 0;
  color: white;
  text-align: center;
  width: 100%;
}
.detailscard-main h1 {
}
.know-more-icon {
  color: white;
  margin: 0 2px;
  font-size: 1rem;
}
.detailscard-main p {
  padding: 10px 0;
  max-width: 80%;
  margin: 0 auto;
  font-size: 1.4rem;
  cursor: pointer;
  opacity: 0.8;
}
p.know-more-text {
  font-size: 1rem;
  border-bottom: 1px solid gray;
  display: inline-flex;
  padding: 2px;
  width: 10%;
  justify-content: space-evenly;
}

p.know-more-text:hover .know-more-icon {
  transform: translate(11px, 0);
  transition: 1.5s ease;
}
.domaincard-main {
  /* position: relative; */
  /* top: -20px; */
}
.domaincard {
  background: #ff6043e0;
  color: white;
  padding: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.domaincard-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.domaincard-section h3 {
  margin: 10px 0;
}
.domaincard-section p {
  max-width: 80%;
  font-size: 1.1rem;
}
.domaincard-section svg {
  height: 30px;
}

/* collabrate */
#collabratecard-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 100px 0;
}
.card h1 {
  font-size: 2.5rem;
}
.card p {
  margin: 6px 0;
  opacity: 0.8;
  font-weight: bold;
  letter-spacing: 1px;
}
.blogsection-main {
  display: flex;
  color: #1b1b1b;
  margin: 0 0 50px 0;
}
.blog-tags {
  flex-wrap: wrap;
  width: 80%;
  display: flex;
}
.blog-tags span {
  color: white;
  padding: 5px 11px;
  border-radius: 25px;
  margin: 0 5px 5px 0;
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
  outline: 2px transparent solid;
  border: 1px white solid;
}
.blog-left {
  width: 30%;
}
.blog-left p {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 10px 0;
}
input#blog-search {
  padding: 6px 20px;
}

/* doesnt work funnly on firefox or edge, need to fix */

.range-slider {
  width: 300px;
  text-align: center;
  position: relative;
}
.rangeValues {
  display: block;
}
input[type="range"] {
  -webkit-appearance: none;
  border: 1px solid white;
  width: 300px;
  position: absolute;
  left: 0;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
  margin-top: -4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type="range"]::-moz-range-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}

/*hide the outline behind the border*/

input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  width: 300px;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
  z-index: -4;
}

input[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #888;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #ccc;
}

.blog-right {
  background: #fbfbfb;
  width: 70%;
  padding: 55px;
  border-radius: 12px;
}

.blogitem {
  padding: 35px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
}
.blog-list a {
  color: #1b1b1b;
}
.blogitem:hover {
  background: white;
}

.blogitem p {
  font-size: 1.2rem;
  opacity: 0.8;
  margin: 10px 0;
}
p.blog-tag {
  background: #1e1e1e;
  opacity: 0.6;
  color: white;
  width: fit-content;
  padding: 5px 11px;
  border-radius: 25px;
}
p.blog-date {
  font-size: 0.9rem;
  opacity: 0.5;
  margin: 10px 0;
}

.blog-rightArrow {
  font-size: 26px;
  color: #1b1b1bc7;
  margin: 0 5px;
}
.blogitem a {
  text-decoration: none;
  color: #1b1b1b;
}
.blog-readmore {
  align-items: center;
  display: flex;
}

.recentblogs-main {
  align-items: center;
  text-align: center;
  display: flex;
  margin: 50px 0;
}

.recentblogs-main h1 {
  margin: 0 0 20px 0;
}

.recentblogs-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: center;
}
.recentblogs-item {
  width: 27.33%;
  background: white;
  color: #ff735a;
  border: 2px #ff735a99 solid;
  text-align: left;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  padding: 30px 20px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.recentblogs-item a {
}

.recentblogs-item a {
  border: 1px #ff735a solid;
  margin: 22px 0 0 0;
  color: white;
  background-color: #ff735a;
  padding: 6px 12px;
  border-radius: 20px;
  bottom: 8%;
  display: flex;
}

.recentblogs-item svg {
  font-size: 20px;
}
.recentblogs-item:hover a > svg {
  transform: translateX(5px);
  transition: 0.4s all;
}
.recentblogs-main p {
  margin: 20px 0;
  font-weight: bold;
  letter-spacing: 1.5px;
}
.recentblogs-item p {
  margin: 5px;
  font-weight: 100;
  letter-spacing: normal;
}

.recentblogs-top svg {
  font-size: 55px;
}
.recentblogs-item h3 {
  font-size: 25px;
}
.recentblogs-item p {
  font-size: 18px;
}

.technologies-main {
  align-items: center;
  text-align: center;
  display: flex;
  padding: 100px 0;
  position: relative;
  width: 100%;
}

.technologies-items {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.technologies-item {
  min-width: 128px;
  width: 15%;
  height: 17vh;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.technologies-item img {
  height: 100%;
  width: 73px;
  object-fit: cover;
  margin: 0 20px;
}
.technologies-item img:hover {
  transform: scale(1.2);
  transition: 0.2s all;
}

.letstalkform-main {
  margin: 30px 0;
  height: 67vh;
  height: 100%;
  display: flex;
  color: #1e1e1e;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.form-inputs {
  font-size: 1.1rem;
}
.form-inputs input {
  margin: 10px 0;
}
.form-inputs textarea {
  margin: 10px 0;
}
.form-inputs input {
  padding: 10px 20px;
  border: 1px #1b1b1b70 solid;
  width: 100%;
  font-size: 20px;
  outline: none;
}
.form-inputs textarea {
  padding: 10px 20px;
  border: 1px #1b1b1b70 solid;
  width: 100%;
  font-size: 20px;
  outline: none;
}

.form-inputs select {
  padding: 10px 20px;
  border: 1px #1b1b1b70 solid;
  width: 100%;
  font-size: 20px;
  outline: none;
}
.form-inputs {
  text-align: left;
  margin: 50px 0;
  color: #1b1b1bc7;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.form-inputs .form-name {
  width: 45%;
}
.form-inputs .form-email {
  width: 45%;
}
.form-inputs .form-message {
  width: 100%;
}
.letstalk-StyledSubmitMessageDiv p {
  margin: 50px 0;
  padding: 10px;
  color: white;
}
#failed {
  border: 1px #ff8686 solid;
}
#success {
  border: 1px #87ffa3 solid !important;
}

/* about page */
/* work section */
/* work section */
.projects-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.projectItem {
  height: 400px;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  cursor: pointer;
  border: 4px transparent solid;
  align-items: center;
  border-radius: 20px;
  background: #8080800d;
}
.projectItem a {
  text-decoration: none;
  color: inherit;
  width: 30%;
}
.projectItem:hover {
  /* border: 4px rgba(63, 63, 63, 0.294) solid;  */
}
.projectItem-img {
  border-radius: 20px;
  transition: 1s ease-in-out;
  height: 50%;
  width: 357px;
}
.projectItem:hover .projectItem img {
  box-shadow: 0 0 10px 6px black inset;
}
.projectItem img {
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  border-radius: 20px;
  object-fit: cover;
  position: relative;
  z-index: -1;
}
.projectItem:hover .projectItem img {
  transform: scale(1.02);
  transition: 0.5s ease;
}
.projectItem-techs {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.projectItem-desc {
  width: 100%;
  justify-content: space-between;
  padding: 25px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50%;
}
.projectItem-techs span {
  padding: 5px;
  border-radius: 19px;
  font-size: 0.8rem;
  border: 2px rgb(63, 63, 63) solid;
  margin: 5px 0;
}
/* end work section */

.about-slider {
  display: flex;
  justify-content: space-evenly;
  height: 74vh;
  align-items: center;
  flex-wrap: wrap-reverse;
}
.about-left {
  width: 50%;
  display: flex;
  justify-content: center;
}
.about-right {
  width: auto;
}
.about-right img {
  height: 400px;
  /* filter: grayscale(100); */
  width: auto;
}
.about-left h1 {
  /* color: #1e1e1e; */
  width: 89%;
  font-size: 3rem;
  text-align: left;
}

/* about page end */
.experience-items {
  width: 60%;
  display: flex;
  justify-content: center;
  border-left: 5px #1e1e1e69 solid;
}
.experience-section {
  margin: 17px 0 50px 0;
}
.experience-text {
  font-size: 1rem !important;
  padding: 20px 0;
}
tr {
  justify-content: space-between;
  display: flex;
  margin: 20px 0;
}
.experience-section h1 {
  margin: 0 0 60px 0;
}
.experience-details {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.experience-details h2 {
  padding: 5px 0;
  font-size: 1.3rem;
}
.experience-details h3 {
  opacity: 0.5;
  font-size: 1.1rem;
}
.experience-details h4 {
  opacity: 0.4;
  font-size: 0.9rem;
}
.experience-image {
  display: flex;
  width: 30%;
  justify-content: right;
}
.experience-image img {
  padding: 6px;
  height: 50px;
  width: 50px;
  border: 2px #cdcdcd solid;
  border-radius: 50%;
  object-fit: cover;
}

/* */
.photographyGallery-buttons {
  width: 30vw;
  display: flex;
  margin: 25px 0;
  justify-content: space-between;
}
.photographyGallery-buttons button {
  background: white;
  border: 1px #1e1e1ecc solid;
  cursor: pointer;
  padding: 10px 15px;
}
.photographyGallery-buttons button:hover {
  background: #dfdfdfc7;
}
.ImageGalleryMainDiv ul {
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
}
.ImageGalleryMainDiv img {
}

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -moz-column-width: 33%;
  -webkit-column-width: 33%;
}
.gallery .pics {
  transition: all 350ms ease;
  -webkit-transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}
.InstagramConnectCardSection {
  width: 100%;
  justify-content: center;
  display: flex;
}
.InstagramConnectCard {
  display: flex;
  width: 21%;
  margin: 20px 0;
  justify-content: space-evenly;
  align-items: center;
  /* border: 3px #1b1b1b solid; */
  border-radius: 54px;
}
.InstagramConnectCard img {
  height: 65px;
}
/* admin  */
.availableCategoryList {
  width: 100%;
  padding: 15px 0;
}
.availableCategoryItem {
  display: flex;
  width: 100%;
  padding: 3px 0;
  justify-content: space-between;
  -webkit-transition: 1s ease-in-out;
  transition: 1s;
}
.availableCategoryItem img {
  height: 150px;
  width: 150px;
  object-fit: cover;
}
.photographsManagementList {
  width: 100%;
  justify-content: center;
}
.photographsList {
  width: 50%;
}
.photographsItem {
  justify-content: space-between;
  display: flex;
  padding: 5px 0;
  border-bottom: 1px rgba(128, 128, 128, 0.16) solid;
}
/* admin end */
@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -moz-column-width: 100%;
    -webkit-column-width: 100%;
    column-width: 100%;
  }
}
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}
.model img {
  height: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
  box-shadow: 0px 0px 20px 20px #0808080a;
}
.model svg {
  position: fixed;
  color: white;
  right: 15px;
  top: 15px;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  padding: 5px;
  background-color: rgb(0, 0, 0, 0.4);
}
.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  background-color: rgba(0, 0, 0, 0.34);
}

.availableCategorySection svg {
  cursor: pointer;
  color: red;
}
/*  */
.footer-main {
  height: 65vh;
  width: 100%;
  background-color: #1e1e1e;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  position: relative;
}
.footer-socialmedia {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.footer-socialmedia-icon {
  color: white;
  font-size: 40px;
}
.footer-socialmedia-icon:hover {
  opacity: 0.7;
}
.footer-main h2 {
  padding: 30px;
}

.footer-copyright {
  position: absolute;
  text-align: center;
  bottom: 0;
  padding: 10px 0;
}

/*  */
.responsive-menu-main {
  cursor: pointer;
  display: none !important;
}

/*  */

/* responsive  */

@media only screen and (max-width: 1440px) {
  .container {
    width: 1366px;
  }
  .detailscard-main {
    width: 100%;
  }
}
@media only screen and (max-width: 1365px) {
  .container {
    width: 1280px;
  }
}
@media only screen and (max-width: 1279px) {
  .container {
    width: 1024px;
  }
  .photographyGallery-buttons {
    width: 50vw;
  }
}
@media only screen and (max-width: 1023px) {
  .container {
    width: 768px;
  }
  .header-nav {
    width: auto;
  }

  .header-logo h1 {
    font-size: 3rem;
  }
  .responsive-menu-main {
    display: block !important;
  }
  .slider-heading h1 {
    font-size: 3.5rem;
  }
  .responsive-darkMode-button {
    bottom: 10%;
    right: 14%;
    position: fixed;
    list-style: none;
    z-index: 99;
    opacity: 0.7;
  }

  /* ABOUT PAGE */
  .about-right img {
    height: 300px;
  }
  .about-left h1 {
    font-size: 2rem;
  }
  .center {
    width: 40px;
    height: 40px;
  }
  /* ABOUT PAGE END */

  /* BLOG PAGE */
  .blogitem h1 {
    font-size: 1.8rem;
  }
  .blogitem p {
    font-size: 1.1rem;
  }

  /* BLOG PAGE END */

  /* PHOTOGRAPHY PAGE */
  .photographyGallery-buttons {
    width: 50vw;
  }
  /* PHOTOGRAPHY PAGE END */
}
@media only screen and (max-width: 767px) {
  .container {
    width: 640px;
  }
  .slider-heading h1 {
    font-size: 2.5rem;
  }
  .slider-heading p {
    font-size: 1.1rem;
  }
  .footer-socialmedia {
    width: 50%;
  }

  /* ABOUT PAGE */
  .about-left h1 {
    font-size: 1.7rem;
  }
  .about-right img {
    height: 282px;
  }
  /* ABOUT PAGE END */

  /* BLOG PAGE */
  .blogsection-main {
    display: block;
  }
  .blog-left {
    width: 100%;
  }
  .blog-tags {
    flex-wrap: wrap;
    width: 62%;
  }
  .blog-tags span:hover {
  }
  .blog-right {
    background: #fbfbfb;
    width: 100%;
  }
  .about-slider {
    height: 75vh;
  }
  .blogitem h1 {
    font-size: 1.7rem;
  }
  .blogitem p {
    font-size: 1rem;
  }
  /* BLOG PAGE END */
  .modal-content h2 {
    font-size: 1.2rem;
  }
  .modal-content {
    width: 60% !important;
  }
  /* PHOTOGRAPHY PAGE */
  .photographyGallery-buttons {
    width: 50vw;
  }
  /* PHOTOGRAPHY PAGE END */
}
@media only screen and (max-width: 639px) {
  .container {
    width: 480px;
    padding: 0 15px;
  }
  .slider-main {
    flex-wrap: wrap-reverse;
    height: 90vh;
    align-content: space-evenly;
  }

  /* ABOUT PAGE */
  .about-left h1 {
    font-size: 1.5rem;
  }
  .about-right img {
    height: 221px;
  }
  .center {
    width: 36px;
    height: 36px;
  }
  .experience-items {
    width: 85%;
  }

  /* ABOUT PAGE END */

  /* BLOG PAGE  */

  .blog-tags {
    flex-wrap: wrap;
    width: 90%;
  }
  /* BLOG PAGE END */

  /* PHOTOGRAPHY PAGE */
  .photographyGallery-buttons {
    width: 50vw;
  }
  .photographyGallery-buttons button {
    padding: 7px 10px;
  }

  /* PHOTOGRAPHY PAGE END */
  .modal-content h2 {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 479px) {
  .container {
    width: 375px;
  }
  .footer-socialmedia {
    width: 70%;
  }
  .card h1 {
    font-size: 1.5rem;
  }
  .card p {
    font-size: 0.7rem;
  }
  .detailscard-main p {
    max-width: 100%;
    font-size: 1.3rem;
  }
  .header-logo h1 {
    font-size: 2.6rem;
  }
  /* about page */
  .about-left {
    width: 87%;
  }
  .about-left h1 {
    width: 89%;
    font-size: 1.3rem;
  }
  .about-right img {
    height: 249px;
    width: auto;
  }
  .center {
    width: 30px;
    height: 30px;
  }
  .experience-items {
    width: 90%;
  }
  /* about page end */
  /* blog page start */
  .blog-tags {
    width: 100%;
  }
  .blog-list h1 {
    font-size: 1.5rem;
  }
  .blog-list p {
    font-size: 1rem;
  }
  /* blog page end */

  /* LET'S TALK PAGE */

  .form-inputs .form-name {
    width: 100%;
  }
  .form-inputs .form-email {
    width: 100%;
  }
  .letstalkform-main h1 {
    font-size: 1.5rem;
  }
  /* LET'S TALK PAGE END */

  /* PHOTOGRAPHY PAGE */
  .photographyGallery-buttons {
    width: 60vw;
  }
  /* PHOTOGRAPHY PAGE END */
  .modal-content a {
    font-size: 0.8rem;
  }
  .modal-content h2 {
    font-size: 0.9rem;
  }
  .modal-content {
    width: 70% !important;
  }
  .projectItem-img {
    height: 352px;
    width: 275px;
  }
  .projectItem-techs {
    width: 90%;
  }
}
@media only screen and (max-width: 374px) {
  .container {
    width: 320px;
  }
  .card p {
    font-size: 0.6rem;
  }
  .slider-heading p {
    font-size: 0.9rem;
  }
  .slider-heading h1 {
    font-size: 1.8rem;
  }
  .footer-socialmedia {
    width: 85%;
  }
  .header-main {
    height: 15vh;
  }
  .slider-main {
    height: 85vh;
  }
  .modal-content {
    width: 100%;
  }
  .Modal-closeButton svg {
    font-size: 17px;
  }
  .modal-content {
    width: 80% !important;
  }
  /* PHOTOGRAPHY PAGE */
  .photographyGallery-buttons {
    width: auto;
  }
  /* PHOTOGRAPHY PAGE END */
}

/* responsive  end*/
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal-content {
  width: 47%;
  height: 46%;
  background-color: white;
  padding: 25px;
  border-radius: 15px;
}
#modal-main {
  height: 100%;
}
.modal-content svg {
  font-size: 3rem;
}
.modal-content-details h5 {
  opacity: 0.8;
  margin: 10px;
  font-size: 15px;
}
.modal-content-details {
  min-height: 69%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.Modal-closeButton {
  display: flex;
  justify-content: right;
}
.modal-content-details a {
  background-color: #1b1b1b;
  padding: 8px 18px;
  color: white;
  border-radius: 11px;
}
.Modal-closeButton svg {
  font-size: 23px;
  cursor: pointer;
}
