body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  scroll-behavior: smooth;
}
.custom-button1 {
  padding: 10px 34px;
  border: 2px solid #1b1b1b;
  color: #1b1b1b;
  font-weight: bold;
  border-radius: 50px;
  margin: 15px 0;
  font-size: 20px;
  background: linear-gradient(to right, #1b1b1b 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.6s ease-out;
  display: inline-block;
}

.custom-button1:hover {
  background-color: #1b1b1b;
  background-position: left bottom;
  color: white;
}
